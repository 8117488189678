<template>
  <va-modal
    v-model="s_data"
    @cancel="onCancel"
    hideDefaultActions
    class="flex xs12"
    title="Tickets Modal"
    size="large"
    ok-text="Ok"
  >
    <va-data-table
      :fields="ticketsFields"
      :data="ticketsFilteredData"
      :per-page="5"
    >
      <template slot="actions" slot-scope="props" >
        <va-button
           v-if="enableEdit"
          @click="editCategory(props.rowData)"
          color="info"
          icon="fa fa-pencil"
          class="mr-2"
        />
        <va-button v-else color="info" icon="fa fa-lock" class="mr-2" disabled title="Edit disabled, is this Ticket assigned to an admin?"/>
      </template>
    </va-data-table>
    <div class="pt-2">
      <va-button slot="actions" @click="onCancel" color="primary" class="mr-2">
        Close
      </va-button>
    </div>
  </va-modal>
</template>
<script>
export default {
  name: "TicketData",
  props: {
    enableEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
    },
    ticketsFilteredData: {
      required: true,
    },
    categories: {
      required: false,
    },
  },
  data() {
    return {
      s_data: false,
    };
  },
  computed: {
    ticketsFields() {
      return [
        {
          name: "header",
          title: "Header",
          sortField: "header",
          width: "40%",
        },
        {
          name: "rawCategory",
          title: "Category",
          sortField: "rawCategory",
          width: "10%",
        },
        {
          name: "answerLabel",
          title: "Label",
          sortField: "answerLabel",
          width: "10%",
        },
        {
          name: "datails",
          title: "Details",
          sortField: "datails",
          width: "10%",
        },
        {
          name: "answerChoice",
          title: "Selected",
          sortField: "answerChoice",
          width: "15%",
        },
        {
          name: "__slot:actions",
          title: "Actions",
          width: "15%",
        },
      ];
    },
  },
  methods: {
    onCancel() {
      this.$emit("cancel");
    },
    editCategory(rowData) {
      //show swal with options to select a category
      let options = this.categories;
      const inputOptions = options.reduce((o, i) => {
        o[i.id] = i.name;
        return o;
      }, {});
      //show sweet alert with select containing options
      this.$swal({
        title: "Select a category",
        html: 'Not in the list? Create a new category <a href="/tickets/custom-categories">here</a>',
        input: "select",
        inputOptions: inputOptions,
        confirmButtonText: 'Select <i class="fa fa-arrow-right"></i>',
        inputPlaceholder: "Select a category",
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value !== "") {
              resolve();
            } else {
              resolve("You need to select a category");
            }
          });
        },
      }).then((result) => {
        if (result.value) {
          //replace category and rawCategory with the selected category
          rowData.category = result.value;
          rowData.rawCategory = inputOptions[result.value];
          //update the ticket
          this.$emit("editCategory", this.ticketsFilteredData);
        }
      });
    },
  },
  watch: {
    data: {
      handler() {
        this.s_data = this.data;
      },
      immediate: true,
    },
  },
};
</script>
