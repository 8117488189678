import Swal from 'sweetalert2';
import axios from '@/scripts/interceptor.js'

class TicketCatcher {

    selectedReply = false;

    getTicketLabels(answers, categories){
        let tickets = [];
        answers.forEach((answer) => {
          let category = categories.find((cat) => {
              return cat.id === answer.category;
          });
          let tLabel = {
            header: answer.header,
            rawCategory: category ? category.name : "-", 
            category: answer.category,
            parent: answer?.parent || false,
            datails: answer?.parent?.answerChoice || '-',
            answerLabel: answer.answerLabel || '-',
            answerChoice: answer.answerChoice,
            needsTicket: answer.needsTicket,
            id: answer.id,
          }
          tickets.push(tLabel);
        });
        return tickets;
    }

    /**
     * @description This method will throw a sweetalert with options to reply the ticket
     * @param {*} htmlSelectOptions 
     * @param {*} data 
     * @param {*} replies 
     */
    reply(htmlSelectOptions, data, replies){

        return new Promise((resolve, reject) => {
            Swal.fire({
                title: 'Reply This Ticket',
                html:
                'The message will be sent to the following email address: <b>' + data.email +
                `</b><select id="swal-input1" class="swal2-input"> ${htmlSelectOptions}  </select>` +
                `<textarea style="resize: none; min-height: 100px; padding-top: 13px ;" id="swal-input2" class="swal2-input" placeholder="Type your message here"></textarea>`,
                confirmButtonText: 'Reply Ticket',
                showCancelButton: true,
                onOpen: () => {
                //add event listener to select input
                document.getElementById('swal-input1').addEventListener('change', (e) => {
                    //check if is different from default
                    if(e.target.value == 'default'){
                    this.selectedReply = false;
                    }else{
                    //find in this.replies the one that has key = e.target.value
                    this.selectedReply = replies.find((item) => {
                        return item.key === e.target.value;
                    });
                    }
                    document.getElementById('swal-input2').value = this.selectedReply.message != undefined ? this.selectedReply.message : '';
                });
                },
                preConfirm: () => {
                return {
                    label: document.getElementById('swal-input1').options[document.getElementById('swal-input1').selectedIndex].text,
                    value: document.getElementById('swal-input1').value,
                    TextArea: document.getElementById('swal-input2').value
                }
                }
            }).then((result) => {
                if (result.value) {
                    //check if this.selectedReply.message is not empty
                    if(result.value.TextArea == ''){
                        Swal.fire({
                            title: 'Error!',
                            text: 'Please select a model or type a message',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        });
                        return;
                    }
                    let message = this.selectedReply?.message != result.value?.TextArea ? result.value?.TextArea : this.selectedReply?.message;
                    Swal.fire({
                        title: 'Replying Ticket',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        onOpen: () => {
                            let that = this;
                            Swal.showLoading();
                            axios.post(`api/admin/replyTicket`,{
                                ticketID: data.id,
                                mailto: data.email,
                                message: message,
                            }).then((result)=>{
                                //confirm success
                                resolve();
                                Swal.fire({
                                    title: 'Success!',
                                    text: 'Message has been sent',
                                    icon: 'success',
                                    confirmButtonText: 'OK'
                                }).then(() => {
                                    Swal.hideLoading();
                                });
                            })
                            .catch((error) => {
                                Swal.hideLoading();
                                reject();
                                Swal.fire({
                                    title: 'Error!',
                                    text: error.message,
                                    icon: 'error',
                                    confirmButtonText: 'OK'
                                });
                            });
                        },
                    });
                }
            });
        });
    }


    /**
     * @description This method will catch the ticket and return the data
     * @param {*} uniqueAnswers 
     * @param {*} categories 
     */
    handleCategories (uniqueAnswers, categories) {
        let r_categories = [];   
        uniqueAnswers.forEach(answer => {
            let categoryItem = categories.find((cat) => {
                return cat.id === answer;
            });
    
            let category = 'N/A';
            
            if(categoryItem && categoryItem.name) {
                category = categoryItem.name;
            }
            
            r_categories.push(category);
        });
        return r_categories;
    }
    
}


/**
 * @type {TicketCatcher}
 * @description We don't have a constructor, so we can use this class as a singleton
*/
export default new TicketCatcher();